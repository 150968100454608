// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: {
    root: path(ROOTS_DASHBOARD, '/main'),
    invoices: path(ROOTS_DASHBOARD, '/main/trades'),
    indexValues: path(ROOTS_DASHBOARD, '/main/index-values'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    invite: path(ROOTS_DASHBOARD, '/user/invite'),
    client: path(ROOTS_DASHBOARD, '/user/client/:userId/dashboard'),
    activeTrading: path(ROOTS_DASHBOARD, '/user/client/active-trading'),
    partner: path(ROOTS_DASHBOARD, '/user/partner/:userId/dashboard'),
    profile: path(ROOTS_DASHBOARD, '/user/profile/:userId'),
  },
  statistics: {
    root: path(ROOTS_DASHBOARD, '/statistics'),
  },
  payoutRequests: {
    root: path(ROOTS_DASHBOARD, '/payout-requests'),
  },
  vipManage: {
    root: path(ROOTS_DASHBOARD, '/vip-manage'),
    new: path(ROOTS_DASHBOARD, '/vip-manage/new'),
  },
  affiliate: {
    root: path(ROOTS_DASHBOARD, '/affiliate'),
    list: path(ROOTS_DASHBOARD, '/affiliate/list'),
  },
  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    newPost: path(ROOTS_DASHBOARD, '/news/new-post'),
    detail: path(ROOTS_DASHBOARD, '/news/posts/:postId/:title'),
    edit: path(ROOTS_DASHBOARD, '/news/posts/:postId/:title/edit'),
  },
};
