import { redirectUrl } from 'src/config';
import {
  ForgotPasswordRestAdapterApi,
  LoadLoggedInUserRestAdapterApi,
  RegistrationRestAdapterApi,
  RegistrationRestAdapterApiRegisterRequest,
  SendInvitationRequest,
  SendInvitationRestAdapterApi,
  UserDTO,
} from './generated/common';
import { LoginRestAdapterApi } from './generated/common/api/login-rest-adapter-api';
import { ResetPasswordRestAdapterApi } from './generated/common/api/reset-password-rest-adapter-api';
import { Configuration, ConfigurationParameters } from './generated/common/configuration';
import { LoginRequest } from './generated/common/model/login-request';
import { LoginResponse } from './generated/common/model/login-response';
import { ResetPasswordRequest } from './generated/common/model/reset-password-request';

export const getToken = (): string | null => window.localStorage.getItem('accessToken');

export type AuthConfigurationOption = {
  options?: ConfigurationParameters;
};

export const getAuthConfiguration = ({
  options = {},
}: AuthConfigurationOption = {}): Configuration => {
  const token = getToken();
  // const isValid = token ? isTokenStillValid(token) : false;
  //TODO: validate token and refresh if needed
  // if (token && !isValid) {
  //   await RefreshAndSetTokenService.refreshAndSetToken();
  //   token = await getToken();

  return new Configuration({ ...options, baseOptions: {}, accessToken: token || undefined });
};

export const loginUser = async (loginRequest: LoginRequest): Promise<LoginResponse> => {
  const api = new LoginRestAdapterApi();

  return (await api.login({ loginRequest })).data;
};

export const getUser = async (): Promise<UserDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadLoggedInUserRestAdapterApi(conf);

  return (await api.loadCurrentUser()).data;
};

export const forgotPassword = async (email: string): Promise<object> => {
  const api = new ForgotPasswordRestAdapterApi();

  return (
    await api.forgotPassword1({
      forgotPasswordRequest: { redirectUrl: redirectUrl.partnerBaseUrl, email: email },
    })
  ).data;
};

export const changeUserPassword = async (
  resetPasswordRequest: ResetPasswordRequest
): Promise<object> => {
  const api = new ResetPasswordRestAdapterApi();

  return (await api.resetPassword({ resetPasswordRequest })).data;
};

export const registerUser = async (
  requestParameters: RegistrationRestAdapterApiRegisterRequest
): Promise<UserDTO> => {
  const api = new RegistrationRestAdapterApi();

  return (await api.register(requestParameters)).data;
};

export const sendInvitation = async (
  sendInvitationRequest: SendInvitationRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new SendInvitationRestAdapterApi(conf);

  return (await api.sendInvitation({ sendInvitationRequest })).data;
};
