import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
// guards
import useAuth from '../hooks/useAuth';
import AdminDashboardLayout from '../layouts/dashboard/index';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <AdminDashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        {
          children: [
            { path: 'main', element: <AdminMain /> },
            { path: 'main/trades', element: <AdminTradeList /> },
            { path: 'main/index-values', element: <AdminIndexValues /> },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/list" replace />,
              index: true,
            },
            { path: 'list', element: <AdminUserList /> },
            { path: 'invite', element: <AdminUserInvite /> },
            { path: 'client/active-trading', element: <ActiveTradingPage /> },
            { path: 'client/:userId/dashboard', element: <AdminClientDetail /> },
            { path: 'partner/:userId/dashboard', element: <AdminPartnerDetail /> },
            { path: 'profile/:userId', element: <AdminUserProfile /> },
          ],
        },
        {
          path: 'affiliate',
          children: [
            {
              element: <Navigate to="/dashboard/affiliate/list" replace />,
              index: true,
            },
            { path: 'list', element: <AdminAffiliate /> },
            // { path: ':clientId/dashboard', element: <ClientDashboardPage /> },
          ],
        },
        {
          path: 'news',
          children: [
            {
              element: <Navigate to="/dashboard/news/posts" replace />,
              index: true,
            },
            { path: 'posts', element: <AdminNews /> },
            // { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <AdminNewsNewPost /> },
            { path: 'posts/:postId/:title', element: <AdminNewsDetail /> },
            { path: 'posts/:postId/:title/edit', element: <AdminNewsNewPost /> },
          ],
        },
        { path: 'statistics', element: <AdminStatistics /> },
        { path: 'payout-requests', element: <AdminPayoutRequests /> },
        { path: 'vip-manage', element: <AdminVipManage /> },
        {
          path: 'vip-manage',
          children: [
            {
              path: '',
              element: <AdminVipManage />,
              index: true,
            },
            { path: 'new', element: <AdminNewGroupTransaction /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
//Admin Dashboard
const AdminMain = Loadable(lazy(() => import('../pages/dashboard/AdminMain')));
const AdminIndexValues = Loadable(lazy(() => import('../pages/dashboard/AdminIndexValues')));
const AdminTradeList = Loadable(lazy(() => import('../pages/dashboard/AdminTradeList')));
const AdminUserList = Loadable(lazy(() => import('../pages/dashboard/AdminUserList')));
const AdminUserInvite = Loadable(lazy(() => import('../pages/dashboard/AdminUserInvite')));
const AdminUserProfile = Loadable(lazy(() => import('../pages/dashboard/AdminUserProfile')));
const AdminClientDetail = Loadable(lazy(() => import('../pages/dashboard/AdminClientDetail')));
const AdminStatistics = Loadable(lazy(() => import('../pages/dashboard/AdminStatistics')));
const AdminNews = Loadable(lazy(() => import('../pages/dashboard/AdminNews')));
const AdminNewsNewPost = Loadable(lazy(() => import('../pages/dashboard/AdminNewsNewPost')));
const AdminNewsDetail = Loadable(lazy(() => import('../pages/dashboard/AdminNewsDetail')));
const AdminPartnerDetail = Loadable(lazy(() => import('../pages/dashboard/AdminPartnerDetail')));
const AdminPayoutRequests = Loadable(lazy(() => import('../pages/dashboard/AdminPayoutRequests')));
const AdminAffiliate = Loadable(lazy(() => import('../pages/dashboard/AdminAffiliate')));
const ActiveTradingPage = Loadable(lazy(() => import('../pages/dashboard/ActiveTradingPage')));
const AdminVipManage = Loadable(lazy(() => import('../pages/dashboard/AdminVipManage')));
const AdminNewGroupTransaction = Loadable(
  lazy(() => import('../pages/dashboard/AdminNewGroupTransaction'))
);

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
